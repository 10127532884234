.tm-desktop{
    display: block;
}
.tm-mobile{
    display: none !important;
}

@media (max-width: 900px) {
    .tm-desktop{
        display: none !important;
    }
    .tm-mobile{
        display: block !important;
    }
}