.header-container {
    width: 100vw;
    height: 400px;
    padding-right: 50;
}

.back-img {
    background-image: url('../Assets/back3_header.png');
    width: "100%";
    height: 400px;
    background-position: center;
    background-size: cover;
}

@media (max-width: 500px) {
    .header-container {
        width: "100%";
        height: 300px;
    }

    .back-img {
        width: "100%";
        height: 300px;
    }
}

.img-filter {
    /* background-color:#012036; */
    background-color: black;
    opacity:0.3;
    z-index: 1;
}